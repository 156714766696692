import * as authService from '../api/authService';
import { routeUtils } from 'tds-common-fe';
import * as urls from '../components/Routes/urls';
import { APIError, handleError } from '../api/error';
import { useHistory } from 'react-router-dom';

const useGetResetPasswordLink = () => {
    const history = useHistory();

    const handleSubmit = async (email: string, queryParams: { [key: string]: string } = {}) => {
        try {
            await authService.forgotPassword({ username: email });
            history.push(routeUtils.makeQueryPath(urls.AUTH_ROUTES.FORGET_PASSWORD_DONE, { ...queryParams, email }));
        } catch (error) {
            handleError(error as APIError);
        }
    };

    return { handleSubmit };
};

export default useGetResetPasswordLink;
