import React from 'react';
import { Form } from 'antd';
import { NonOptionalKeys, FormItem, routeUtils } from 'tds-common-fe';
import FormattedMessage from '../../../localization/FormatMessage';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AuthInput from '../Custom/AuthInput';
import AuthButton from '../Custom/AuthButton';
import * as urls from '../../Routes/urls';
import AuthBasics from '../Custom/AuthBasics';
import Link from '../../Routes/Link';
import formStyles from '../Form.styl';
import useGetResetPasswordLink from '../../../hooks/useGetResetPasswordLink';

interface FieldsType {
    email: string;
}

const requiredFields: NonOptionalKeys<FieldsType>[] = ['email'];

class Item extends FormItem<FieldsType> {}

const ForgetPassword: React.FunctionComponent = () => {
    const [form] = Form.useForm();
    const { formatMessage } = useFormatMessage();
    const queryParams = routeUtils.parseQuery(location.search);

    const { handleSubmit } = useGetResetPasswordLink();

    const title = <FormattedMessage id="Login.ResetPassword" />;
    const description = <FormattedMessage id="ForgetPassword.Header.Description" />;

    return (
        <div className="signup">
            <AuthBasics title={title} description={description} />
            <Form
                form={form}
                layout="vertical"
                onFinish={(values: FieldsType) => handleSubmit(values.email, queryParams)}
            >
                <Item
                    name="email"
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="SignUp.Field.Email.Error.Empty" />,
                        },
                        { type: 'email', message: <FormattedMessage id="SignUp.Field.Email.Error.Invalid" /> },
                    ]}
                >
                    <AuthInput type="email" placeholder={formatMessage({ id: 'SignUp.Field.Email.Placeholder' })} />
                </Item>
                <Item shouldUpdate>
                    {() => <AuthButton title="Common.Confirm" form={form} requiredFields={requiredFields} />}
                </Item>
                <div className={formStyles.footer_actions}>
                    <FormattedMessage
                        id="ResetPassword.Footer.LoginOrSignUp"
                        values={{
                            login: (
                                <Link to={urls.AUTH_ROUTES.LOGIN}>
                                    <FormattedMessage id="Login.Header.Title" />
                                </Link>
                            ),
                            signUp: (
                                <Link to={urls.AUTH_ROUTES.SIGN_UP}>
                                    <FormattedMessage id="SignUp.Header.Title" />
                                </Link>
                            ),
                        }}
                    />
                </div>
            </Form>
        </div>
    );
};

export default ForgetPassword;
