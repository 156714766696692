import React from 'react';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthBasics from '../Custom/AuthBasics';
import { routeUtils } from 'tds-common-fe';

/*
    This is shown at two places:
        - User clicks 'reset password' on log in page. After inputting email, this page is shown.
        - User clicks 'resend password link' when token from link has expired or is invalid. After clicking button, this page is shown.
 */
const ForgetPasswordDone: React.FunctionComponent = () => {
    // redirect provided if user is requesting link for the first time (directly from sign in), not provided if user request for new link if the link provided in email expires
    const { email = '', redirect_uri: redirectUri } = routeUtils.parseQuery<{ email?: string; redirect_uri?: string }>(
        location.search
    );

    const title = <FormattedMessage id="ForgetPasswordRequest.Header.Title" />;
    const description = (
        <FormattedMessage id="ForgetPasswordRequest.Header.Description" values={{ email: <b>{email}</b> }} />
    );
    const signInLinkText = <FormattedMessage id="ForgetPasswordDone.Button.Back" />;

    return (
        <AuthBasics title={title} signInLinkText={redirectUri ? signInLinkText : undefined} description={description} />
    );
};

export default ForgetPasswordDone;
